import React, {useEffect, useState} from 'react';

interface Props {
    newExpectedText: string;
}

const enum State {
    Typing, Full, Deleting
}

interface Data {
    currentText: string,
    state: State,
}

const initialData: Data = {
    currentText: '',
    state: State.Full,
}

let timerId: number | undefined = undefined;

const TypingText: React.FC<Props> = ({newExpectedText}) => {
    const [{currentText, state}, setData] = useState(initialData);

    useEffect(() => {

        function changeTextTo(expectedText: string) {

            function startDeleting() {
                timerId = window.setInterval(() => {
                    setData((prevData: Data) => {
                        if (prevData.currentText === '') {
                            clearInterval(timerId);
                            timerId = undefined;
                            startTyping();
                            return {
                                currentText: '',
                                state: State.Typing,
                            };
                        } else {
                            return {
                                // currentText: prevData.currentText.substring(0, prevData.currentText.length - 1),
                                currentText: prevData.currentText.replace(/\s*[^\s]+$/g, ''),
                                state: State.Deleting,
                            };
                        }
                    });
                }, 75);
            }

            function startTyping() {
                let parts = expectedText.split(/\s+/);
                let partIndex = 0;
                timerId = window.setInterval(() => {
                    setData((prevData: Data) => {
                        if (prevData.currentText === expectedText || partIndex == parts.length) {
                            clearInterval(timerId);
                            timerId = undefined;
                            return {
                                currentText: expectedText,
                                state: State.Full,
                            };
                        } else {
                            return {
                                // currentText: expectedText.substring(0, prevData.currentText.length + 1),
                                currentText: prevData.currentText + ' ' + parts[partIndex++],
                                state: State.Typing,
                            };
                        }
                    });
                }, 150);
            }

            if (timerId === undefined && state === State.Full && currentText !== newExpectedText) {
                if (currentText === '') {
                    startTyping();
                } else {
                    startDeleting();
                }
            }
        }

        changeTextTo(newExpectedText);

    }, [newExpectedText]);

    return <>{currentText}</>;
};

export default TypingText;
