import React, { useState, useEffect } from 'react';
import TypingText from "./TypingText.component";
import {inspirationalQuotes} from "./Motivaton.const";

interface Props {
    initialText: string;
}

const MotivationalHead: React.FC<Props> = ({ initialText }) => {
    const [currentText, setCurrentText] = useState(initialText);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * inspirationalQuotes.length);
            setCurrentText(inspirationalQuotes[randomIndex]);
        }, 15000);

        return () => clearInterval(intervalId);
    }, [inspirationalQuotes]);

    return <TypingText newExpectedText={currentText} />;
};

export default MotivationalHead;
