import React from 'react';
import {Button} from "react-bootstrap";

type Props = {
    results: string[];
    handleClick: (result: string) => void;
};

const PredefinedResult: React.FC<Props> = ({results, handleClick}) => {

    const predefineResultComponents = results.map((result) => (
            <Button
                key={result}
                className="btn btn-sm btn-success predefinedResultsButton"
                onClick={() => handleClick(result)}
            >
                {result}
            </Button>
    ));
    return (
        <div className="predefinedResultsContainer">
            {predefineResultComponents}
        </div>
    );
};

export default PredefinedResult;
