import React, {useState} from 'react';
import {RunSegmentData, RunSegmentInputs} from './RunSegment.types';
import {convertInputsToData, fillRunSegment,} from "./RunSegment.utils";
import {
    isAllPropsGtz,
    isCalculationRight,
    isNonePropsGtz,
    isRunSegmentDataValid
} from "./RunSegment.validator";
import {BiRefresh} from "react-icons/bi";
import PredefinedResult from "./PredefinedResult.component";

type Props = {
    rowInputs: RunSegmentInputs;
    onDataUpdate: (data: RunSegmentData) => void;
    predefinedPaces?: string[];
    predefinedDistances?: string[];
    predefinedTimes?: string[];
};

const RunSegmentTableRow: React.FC<Props> = ({
                                                 rowInputs,
                                                 onDataUpdate,
                                                 predefinedPaces,
                                                 predefinedDistances,
                                                 predefinedTimes
                                             }) => {
    const [inputs, setInputs] = useState<RunSegmentInputs>(rowInputs);

    const data = convertInputsToData(inputs);
    const invalid = isAllPropsGtz(data) && !isCalculationRight(data);
    const valid = isNonePropsGtz(data) || isRunSegmentDataValid(data);

    function handleEditRow(field: keyof RunSegmentInputs, inputValue: string) {
        const newInputs: RunSegmentInputs = {...inputs, [field]: inputValue};
        setInputs(newInputs);
    }

    function handleUpdateField(field: keyof RunSegmentInputs, value: string) {
        inputs[field] = value;
        handleSaveRow();
    }

    function handleSaveRow() {
        const newInputs = fillRunSegment(inputs);
        const data = convertInputsToData(newInputs);
        if (isRunSegmentDataValid(data)) {
            onDataUpdate(data);
        }
        setInputs(newInputs);
    }

    const refreshAppendInputGroup = (field: keyof RunSegmentInputs) =>
        <div className="input-group-append">
            <button
                className="btn btn-danger btn-right-append"
                type="button"
                onClick={() => handleUpdateField(field, '')}
            >
                <BiRefresh/>
            </button>
        </div>

    return (
        <>
            <td>
                <div className="col-sm-2 input-group">
                    <input
                        className={`form-control centered-input ${!valid && "edit"}`} placeholder="MM:SS"
                        type="text"
                        value={inputs.pace}
                        onBlur={(e) => handleSaveRow()}
                        onChange={(e) => handleEditRow('pace', e.target.value)}
                    />
                    {invalid && refreshAppendInputGroup('pace')}
                </div>
                {predefinedPaces && <PredefinedResult
                    results={predefinedPaces}
                    handleClick={(result) => handleUpdateField('pace', result)}
                />}
            </td>
            <td>
                <div className="col-sm-2 input-group">
                    <input
                        className={`form-control centered-input ${!valid && "edit"}`} placeholder="10km 200m"
                        type="text"
                        value={inputs.distance}
                        onBlur={(e) => handleSaveRow()}
                        onChange={(e) => handleEditRow('distance', e.target.value)}
                    />
                    {invalid && refreshAppendInputGroup('distance')}
                </div>
                {predefinedDistances && <PredefinedResult
                    results={predefinedDistances}
                    handleClick={(result) => handleUpdateField('distance', result)}
                />}
            </td>
            <td>
                <div className="col-sm-2 input-group">
                    <input
                        className={`form-control centered-input ${!valid && "edit"}`} placeholder="1h 20m 30s"
                        type="text"
                        value={inputs.time}
                        onBlur={(e) => handleSaveRow()}
                        onChange={(e) => handleEditRow('time', e.target.value)}
                    />
                    {invalid && refreshAppendInputGroup('time')}
                </div>
                {predefinedTimes && <PredefinedResult
                    results={predefinedTimes}
                    handleClick={(result) => handleUpdateField('time', result)}
                />}
            </td>
        </>
    );
};

export default RunSegmentTableRow;
