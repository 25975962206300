import React, {useState} from 'react';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DropResult
} from 'react-beautiful-dnd';
import {
    emptyRunSegmentData,
    emptyRunSegmentInputs,
    RunSegmentData,
    RunSegmentInputs,
    RunSegmentRow
} from './RunSegment.types';
import {calcRestRunSegment, calcRunSegmentOverview, convertDataToFormattedInputs,} from "./RunSegment.utils";
import {v4 as uuidv4} from "uuid";
import RunSegmentTableRow from "./RunSegmentTableRow.component";
import RestRunSegmentTableRow from "./RestRunSegmentTableRow.component";
import {isRunSegmentDataValid} from "./RunSegment.validator";
import {Trash} from 'react-bootstrap-icons';
import {BiExpandVertical} from "react-icons/bi";
import PredefinedResult from "./PredefinedResult.component";
import {convertInputToDistanceInMeters, convertInputToPaceMpKm, convertInputToSeconds} from "./InputConverter";

const restRunSegmentRowId = 'rest-run-segment-row-id';
const predefinedPaces = ['06:00', '05:45', '05:30', '05:15', '05:00', '04:45', '04:30', '04:15', '04:00'];
const predefinedDistances = ['42km 195m', '21km 098m', '10km', '5km'];
const predefinedTimes = ['4h', '3h', '2h', '1h 50m', '1h 40m', '1h 30m', '1h', '50m', '45m', '40m', '35m', '30m'];

interface AppData {
    overview: RunSegmentInputs;
    segmentRows: RunSegmentRow[];
    plan: RunSegmentData;
}

const newRunSegmentRow = (): RunSegmentRow => ({
    segmentId: uuidv4(),
    data: emptyRunSegmentData(),
})

const emptyRestRunSegment = (): RunSegmentRow => ({
    segmentId: restRunSegmentRowId,
    data: emptyRunSegmentData(),
})

const newAppData: AppData = {
    overview: emptyRunSegmentInputs(),
    segmentRows: [emptyRestRunSegment()],
    plan: emptyRunSegmentData(),
}

const RunSegmentTable: React.FC = () => {
    const [appData, setAppData] = useState<AppData>(newAppData);

    // useEffect(() => {
    //     setAppData(updateOverview(appData));
    // }, [appData]);

    function updateOverview(appData: AppData): AppData {
        const rowsData = appData.segmentRows.map((row) => row.data);
        const overviewData = calcRunSegmentOverview(rowsData);
        return {
            ...appData,
            overview: convertDataToFormattedInputs(overviewData),
        }
    }

    function updateRestRow(appData: AppData): AppData {
        const newSegmentRows = [...appData.segmentRows];
        const restSegmentRow = findRestSegmentRow(appData.segmentRows);
        const restSegmentIndex = restSegmentRow ? appData.segmentRows.indexOf(restSegmentRow) : undefined;
        if (restSegmentIndex !== undefined && appData.plan !== undefined) {
            const customRunSegmentData = appData.segmentRows
                .filter((row) => row.segmentId !== restRunSegmentRowId)
                .map((row) => row.data)
                .filter((data) => isRunSegmentDataValid(data));
            const recalcRestSegment = calcRestRunSegment(appData.plan, customRunSegmentData);
            newSegmentRows[restSegmentIndex] = {...newSegmentRows[restSegmentIndex], data: recalcRestSegment};
        }
        return {
            ...appData,
            segmentRows: newSegmentRows,
        }
    }

    function handleSetPlannedData(data: RunSegmentData) {
        setAppData(updateOverview(updateRestRow({
            ...appData,
            plan: data,
        })));
    }

    function handleAddRow() {
        setAppData({
            ...appData,
            segmentRows: [...appData.segmentRows, newRunSegmentRow()],
        });
    }

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const newRows = [...appData.segmentRows];
        const [removed] = newRows.splice(result.source.index, 1);
        newRows.splice(result.destination.index, 0, removed);
        setAppData(({
            ...appData,
            segmentRows: newRows,
        }));
    };

    function findRestSegmentRow(rows: RunSegmentRow[]): RunSegmentRow | undefined {
        return findSegmentRow(rows, restRunSegmentRowId);
    }

    function findSegmentRow(rows: RunSegmentRow[], segmentId: string): RunSegmentRow | undefined {
        return rows.find((row) => row.segmentId === segmentId);
    }

    function handleDeleteRow(segmentId: string) {
        if (restRunSegmentRowId === segmentId) {
            return;
        }
        const delSegment = findSegmentRow(appData.segmentRows, segmentId);
        if (!delSegment) {
            return;
        }
        const index = appData.segmentRows.indexOf(delSegment);
        const newRows = [...appData.segmentRows];
        newRows.splice(index, 1);
        setAppData(updateOverview(updateRestRow({
            ...appData,
            segmentRows: newRows,
        })));
    }

    function handleUpdateRowData(index: number, data: RunSegmentData) {
        const newRows = [...appData.segmentRows];
        newRows[index] = {...newRows[index], data};
        setAppData(updateOverview(updateRestRow({
            ...appData,
            segmentRows: newRows,
        })));
    }

    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <table className="single-table table table-sm table-dark ">
                    <thead className="thead-dark">
                    <tr>
                        <th className="col-sm-1 td-centered"></th>
                        <th className="col-sm-2 td-header"></th>
                        <th className="col-sm-2 td-centered">Pace</th>
                        <th className="col-sm-2 td-centered">Distance</th>
                        <th className="col-sm-2 td-centered">Time</th>
                        <th className="col-sm-1 td-centered"></th>
                    </tr>
                    </thead>
                    <Droppable droppableId="rows">
                        {(dropProvided: DroppableProvided) => (
                            <tbody {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                            {appData.segmentRows.map((row, index) => (
                                <Draggable key={row.segmentId} draggableId={row.segmentId} index={index}>
                                    {(dragProvided: DraggableProvided) => (
                                        <tr
                                            ref={dragProvided.innerRef}
                                            {...dragProvided.draggableProps}
                                            {...dragProvided.dragHandleProps}
                                        >
                                            <td className="col-sm-1 td-centered">
                                                <BiExpandVertical className="drag-icon"/>
                                            </td>
                                            <td className="col-sm-2 td-header">
                                                {restRunSegmentRowId === row.segmentId
                                                    ? 'Rest segment:'
                                                    : `Segment ${index + 1}:`
                                                }
                                            </td>

                                            {restRunSegmentRowId === row.segmentId
                                                ? <RestRunSegmentTableRow
                                                    rowInputs={convertDataToFormattedInputs(row.data)}
                                                />
                                                : <RunSegmentTableRow
                                                    rowInputs={convertDataToFormattedInputs(row.data)}
                                                    onDataUpdate={(data) => {
                                                        handleUpdateRowData(index, data)
                                                    }
                                                    }
                                                />
                                            }

                                            <td className="col-sm-1 td-centered">
                                                {restRunSegmentRowId !== row.segmentId &&
                                                    <button
                                                        className="btn btn-sm btn-danger"
                                                        onClick={() => handleDeleteRow(row.segmentId)}>
                                                        <Trash/>
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </Draggable>
                            ))}

                            {dropProvided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </table>
            </DragDropContext>
            <table className="single-table table table-sm table-dark">
                <tbody>
                <tr>
                    <td className="col-sm-1 td-centered"></td>
                    <td className="col-sm-2 td-header">
                        <button
                            className="btn btn-md btn-success"
                            onClick={handleAddRow}>
                            Add segment
                        </button>
                    </td>
                    <td className="col-sm-2 td-centered"></td>
                    <td className="col-sm-2 td-centered"></td>
                    <td className="col-sm-2 td-centered"></td>
                    <td className="col-sm-1 td-centered"></td>
                </tr>
                <tr>
                    <td className="col-sm-1 td-centered"></td>
                    <td className="col-sm-2 td-header">Total:</td>
                    <td className="col-sm-2 td-centered">{appData.overview.pace}</td>
                    <td className="col-sm-2 td-centered">{appData.overview.distance}</td>
                    <td className="col-sm-2 td-centered">{appData.overview.time}</td>
                    <td className="col-sm-1 td-centered"></td>
                </tr>
                <tr className="predefinedResults">
                    <td className="col-sm-1 td-centered"></td>
                    <td className="col-sm-2 td-header">
                        Planned result
                    </td>
                    <RunSegmentTableRow
                        rowInputs={convertDataToFormattedInputs(appData.plan)}
                        onDataUpdate={(data) => {
                            handleSetPlannedData(data)
                        }}
                        predefinedPaces={predefinedPaces}
                        predefinedDistances={predefinedDistances}
                        predefinedTimes={predefinedTimes}
                    />
                    <td className="col-sm-1 td-centered">
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default RunSegmentTable;
