export const formatPace = (paceInMpKm: undefined | number): string => {
    if (!paceInMpKm) {
        return '';
    }
    const minutes = Math.floor(paceInMpKm);
    const seconds = Math.round(paceInMpKm * 100 % 100 / 100 * 60);
    const formattedMinutes = minutes.toString().padStart(1, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
}
export const formatDistance = (distanceInMeters: undefined | number): string => {
    if (!distanceInMeters) {
        return '';
    }
    const kilometers = Math.floor(distanceInMeters / 1000);
    const meters = Math.floor(distanceInMeters % 1000);
    let result = '';
    if (kilometers > 0) {
        result += `${kilometers}km`;
    }
    if (meters > 0) {
        if (result.length > 0) {
            result += ' ';
        }
        result += `${meters}m`;
    }
    return result;
}
export const formatTime = (timeInSeconds: undefined | number): string => {
    if (!timeInSeconds) {
        return '';
    }
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}