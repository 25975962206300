export interface RunSegmentData {
    paceInMpKm: undefined | number;
    distanceInMeters: undefined | number;
    timeInSeconds: undefined | number;
}

export interface RunSegmentInputs {
    pace: string;
    distance: string;
    time: string;
}

export interface RunSegmentRow {
    segmentId: string;
    data: RunSegmentData;
}

export const emptyRunSegmentData = (): RunSegmentData => {
    return {
        paceInMpKm: undefined,
        distanceInMeters: undefined,
        timeInSeconds: undefined,
    };
}
export const emptyRunSegmentInputs = (): RunSegmentInputs => {
    return {
        pace: '',
        distance: '',
        time: '',
    };
}

export type OptionalNumber = undefined | number;
