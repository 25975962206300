import {RunSegmentData, RunSegmentInputs} from "./RunSegment.types";
import {convertInputToDistanceInMeters, convertInputToPaceMpKm, convertInputToSeconds} from "./InputConverter";
import {formatDistance, formatPace, formatTime} from "./DataFormatter";
import {isRunSegmentDataValid} from "./RunSegment.validator";
import {
    calcField,
    calcPaceFromTimeInSecondsAndDistanceInMeters,
    getEmptyDataFields,
    getEmptyInputsFields
} from "./RunSegment.calculator";

const isNotBlank = (value: string) => {
    return !/^\s*$/g.test(value);
}
export const convertNotEmptyInputsToData = (inputs: RunSegmentInputs): RunSegmentData => {
    return {
        paceInMpKm: isNotBlank(inputs.pace) ? convertInputToPaceMpKm(inputs.pace) : undefined,
        distanceInMeters: isNotBlank(inputs.distance) ? convertInputToDistanceInMeters(inputs.distance) : undefined,
        timeInSeconds: isNotBlank(inputs.time) ? convertInputToSeconds(inputs.time) : undefined,
    }
};
export const convertInputsToData = (inputs: RunSegmentInputs): RunSegmentData => {
    return {
        paceInMpKm: convertInputToPaceMpKm(inputs.pace),
        distanceInMeters: convertInputToDistanceInMeters(inputs.distance),
        timeInSeconds: convertInputToSeconds(inputs.time),
    }
};

export const convertDataToFormattedInputs = (data: RunSegmentData): RunSegmentInputs => {
    return {
        pace: formatPace(data.paceInMpKm),
        distance: formatDistance(data.distanceInMeters),
        time: formatTime(data.timeInSeconds),
    }
};

export const formatInputs = (inputs: RunSegmentInputs): RunSegmentInputs => {
    const data = convertInputsToData(inputs);
    return {
        pace: data.paceInMpKm ? formatPace(data.paceInMpKm) : inputs.pace,
        distance: data.distanceInMeters ? formatDistance(data.distanceInMeters) : inputs.distance,
        time: data.timeInSeconds ? formatTime(data.timeInSeconds) : inputs.time,
    }
};
export const formatData = (data: RunSegmentData): RunSegmentInputs => {
    return {
        pace: formatPace(data.paceInMpKm),
        distance: formatDistance(data.distanceInMeters),
        time: formatTime(data.timeInSeconds),
    }
};


export const getCompletedRunSegmentDataArray = (inputsArray: RunSegmentInputs[]): RunSegmentData[] => {
    return inputsArray
        .map((inputs) => getValidRunSegmentData(inputs))
        .filter((data): data is RunSegmentData => data !== undefined);
}

export const getValidRunSegmentData = (inputs: RunSegmentInputs): undefined | RunSegmentData => {
    const data = {
        paceInMpKm: convertInputToPaceMpKm(inputs.pace),
        distanceInMeters: convertInputToDistanceInMeters(inputs.distance),
        timeInSeconds: convertInputToSeconds(inputs.time),
    }
    return isRunSegmentDataValid(data) ? data : undefined;
}

export const calcRunSegmentOverview = (rows: RunSegmentData[]): RunSegmentData => {
    const totalDistanceInMeters = rows.reduce((acc, row) => acc + (row.distanceInMeters ? row.distanceInMeters : 0), 0);
    const totalTimeInSeconds = rows.reduce((acc, row) => acc + (row.timeInSeconds ? row.timeInSeconds : 0), 0);

    const averagePace = calcPaceFromTimeInSecondsAndDistanceInMeters(totalTimeInSeconds, totalDistanceInMeters);
    return {
        distanceInMeters: totalDistanceInMeters,
        timeInSeconds: totalTimeInSeconds,
        paceInMpKm: averagePace,
    };
}

export const calcRestRunSegment = (expectedResult: RunSegmentData, rows: RunSegmentData[]): RunSegmentData => {
    const overview = calcRunSegmentOverview(rows)
    const restDistanceInMeters = (expectedResult.distanceInMeters || 0) - (overview.distanceInMeters || 0);
    const restTimeInSeconds = (expectedResult.timeInSeconds || 0) - (overview.timeInSeconds || 0);
    const averagePace = calcPaceFromTimeInSecondsAndDistanceInMeters(restTimeInSeconds, restDistanceInMeters);
    const validData = averagePace && restDistanceInMeters > 0 && restTimeInSeconds > 0;
    return {
        distanceInMeters: validData ? restDistanceInMeters : 0,
        timeInSeconds: validData ? restTimeInSeconds : 0,
        paceInMpKm: validData ? averagePace : 0,
    };
}

export const fillRunSegment = (inputs: RunSegmentInputs): RunSegmentInputs => {
    const formattedInputs = formatInputs(inputs);
    const emptyInputFields = getEmptyInputsFields(formattedInputs);
    if (emptyInputFields.length !== 1) {
        return formattedInputs;
    }
    const data = convertNotEmptyInputsToData(formattedInputs);
    const emptyDataFields = getEmptyDataFields(data);
    if (emptyDataFields.length !== 1) {
        console.error('Different empty fields', emptyInputFields, emptyDataFields);
        return formattedInputs;
    }
    const autoCompleteFieldName: keyof RunSegmentData = emptyDataFields.at(0) || 'paceInMpKm';
    const autoCompleteFieldValue = calcField(data, autoCompleteFieldName);
    return convertDataToFormattedInputs({
        ...data,
        [autoCompleteFieldName]: autoCompleteFieldValue,
    });
}
