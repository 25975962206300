export const convertInputToDistanceInMeters = (input: string): undefined | number => {
    const inputValue = input.replaceAll(/\s+/g, '');
    let meters = 0;
    if (/^\d+$/.test(inputValue)) {
        meters = +inputValue;
        if (meters < 100) {
            meters *= 1000;
        }
    } else {
        const regex = /(\d+)(km|m)*/g;
        let match = regex.exec(inputValue);
        while (match) {
            const value = Number(match[1]);
            switch (match[2] || "m") {
                case "km":
                    meters += value * 1000;
                    break;
                case "m":
                    meters += value;
                    break;
            }
            match = regex.exec(inputValue);
        }
    }
    return meters;
}
export const convertInputToPaceMpKm = (input: string): undefined | number => {
    const newInput = input.replaceAll(/[^\d:]+/g, '');
    if (/^\s*\d+\s*(:\s*\d+\s*)?$/.test(newInput)) {
        const parts = newInput.split(":");
        if (parts.length === 1) {
            return Number(parts[0]);
        }
        return Number(parts[0]) + Number((parts[1])?.substring(0, 2)) / 60;
    }
    return undefined;
}
export const convertInputToSeconds = (input: string): undefined | number => {
    let seconds = 0;
    if (/^\d{1,2}:\d{1,2}:\d{1,2}$/.test(input)) {
        const parts = input.split(":").map(Number);
        seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (/^\d{1,2}:\d{1,2}$/.test(input)) {
        const parts = input.split(":").map(Number);
        seconds = parts[0] * 3600 + parts[1] * 60;
    } else if (/^[\d\s]+$/.test(input)) {
        seconds = +(input.replaceAll(/\s+/g, '')) * 60;
    } else{
        const regex = /(\d+)\s*(h|m|s)\s*/g;
        let match = regex.exec(input);
        while (match) {
            const value = Number(match[1]);
            switch (match[2]) {
                case "h":
                    seconds += value * 3600;
                    break;
                case "m":
                    seconds += value * 60;
                    break;
                case "s":
                    seconds += value;
                    break;
            }
            match = regex.exec(input);
        }
    }
    return seconds;
}