import {OptionalNumber, RunSegmentData, RunSegmentInputs} from "./RunSegment.types";
import {convertPaceToSpeedMetersPerSeconds} from "./DataConverter";

export const calcField = (editedRow: RunSegmentData, calcField: keyof RunSegmentData): OptionalNumber => {
    switch (calcField) {
        case 'timeInSeconds':
            return calcTime(editedRow);
        case 'distanceInMeters':
            return calcDistanceInMeters(editedRow);
        case 'paceInMpKm':
            return calcPace(editedRow);
    }
    return undefined;
}
export const getEmptyInputsFields = (inputs: RunSegmentInputs): (keyof RunSegmentInputs)[] => {
    const fieldNames: (keyof RunSegmentInputs)[] = ['time', 'distance', 'pace'];
    return fieldNames.filter((fieldName) => inputs[fieldName] === undefined || /^\s*$/.test(inputs[fieldName]))
};
export const getEmptyDataFields = (data: RunSegmentData): (keyof RunSegmentData)[] => {
    const fieldNames: (keyof RunSegmentData)[] = ['timeInSeconds', 'distanceInMeters', 'paceInMpKm'];
    return fieldNames.filter((fieldName) => data[fieldName] === undefined);
};
export const calcDistanceInMeters = (row: RunSegmentData): OptionalNumber => {
    return row.paceInMpKm && row.timeInSeconds ? convertPaceToSpeedMetersPerSeconds(row.paceInMpKm) * row.timeInSeconds : undefined;
}
export const calcPace = (row: RunSegmentData): OptionalNumber => {
    return calcPaceFromTimeInSecondsAndDistanceInMeters(row.timeInSeconds, row.distanceInMeters);
}
export const calcTime = (row: RunSegmentData): OptionalNumber => {
    return row.paceInMpKm && row.distanceInMeters && row.distanceInMeters > 0
        ? Math.floor(row.paceInMpKm * row.distanceInMeters / 1000 * 60)
        : undefined;
}
export const calcPaceFromTimeInSecondsAndDistanceInMeters = (totalTimeInSeconds: OptionalNumber, totalDistanceInMeters: OptionalNumber): OptionalNumber => {
    return totalDistanceInMeters && totalTimeInSeconds
        ? Number(((totalTimeInSeconds / 60) / (totalDistanceInMeters / 1000)).toFixed(3))
        : undefined;
}