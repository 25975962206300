import React from 'react';
import RunSegmentTable from './RunSegmentTable.component';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MotivationalHead from "./MotivationalHead.component";

function App() {
    return (

        <div className="App ">
            <div className="col-lg-12 col-xl-8 align-run-segment-table">
                <div className="app-header">
                    <img id="myImage" src="./chronometer128.png" alt="AppIcon"/>
                    <h1>
                        <MotivationalHead
                            initialText="Run Iwona!"
                        />
                    </h1>
                </div>
                <RunSegmentTable/>
            </div>
        </div>
    );
}

export default App;
