import React from 'react';
import {RunSegmentInputs} from './RunSegment.types';


type Props = {
    rowInputs: RunSegmentInputs;
};

const RestRunSegmentTableRow: React.FC<Props> = ({rowInputs}) => {
    return (
        <>
            <td className="col-sm-2">
                <input
                    className="form-control centered-input extra-disabled"
                    type="text"
                    value={rowInputs.pace}
                    disabled
                />
            </td>
            <td className="col-sm-2">
                <input
                    className="form-control centered-input extra-disabled"
                    type="text"
                    value={rowInputs.distance}
                    disabled
                />
            </td>
            <td className="col-sm-2">
                <input
                    className="form-control centered-input extra-disabled"
                    type="text"
                    value={rowInputs.time}
                    disabled
                />
            </td>
        </>
    );
};

export default RestRunSegmentTableRow;
