import {RunSegmentData} from "./RunSegment.types";
import {calcPace} from "./RunSegment.calculator";

export const isAllPropsDefined = (obj: any) => Object.values(obj).every((prop) => prop !== undefined);
export const isAllPropsGtz = (obj: any): boolean => Object.values(obj).every((prop) => prop != undefined && (+prop) > 0);
export const isNonePropsGtz = (obj: any) => Object.values(obj).every((prop) => prop == undefined || (+prop) === 0)

export const isRunSegmentDataValid = (data: RunSegmentData): boolean => {
    if (!isAllPropsDefined(data)) {
        return false;
    }
    return isCalculationRight(data);
}

const toFixedComparingPace = (value: number): number => +value.toFixed(8);
const paceAccuracy = toFixedComparingPace(1 / 60 / 2);

export const isCalculationRight = (data: RunSegmentData): boolean => {
    const calculatedPace = calcPace(data);
    return calculatedPace !== undefined
        && data.paceInMpKm !== undefined
        && toFixedComparingPace(Math.abs(calculatedPace - data.paceInMpKm)) < paceAccuracy;
}
