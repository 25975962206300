export const inspirationalQuotes = [
    /****/ "The pain you feel today will be the nothing you feel tomorrow.",
    "You are stronger than you think, and every mile you run proves it.",
    /***/ "Run like there's no tomorrow",
    /***/ "Go get it! Run like there's no tomorrow!",
    "The road to greatness is paved with sweat and determination",
    "Find your stride and let nothing hold you back",
    /***/ "Every step is a victory",
    "Running is not a race, it's a journey",
    "Push your limits, and watch them disappear",
    "Running is my therapy, my escape, my freedom",
    "Run to inspire, to motivate, to be an example",
    /****/ "Sweat now, shine later",
    "Run the day, or the day runs you",
    "Leave nothing on the road",
    /***/ "The only bad run is the one you didn't do",
    /***/ "Find your rhythm, find your peace",
    /****/ "Run with your heart, not just your legs",
    "One run can change your day, many runs can change your life",
    /****/ "Pain is temporary, pride is forever",
    /****/ "Life is short, run fast",
    /****/ "Your only limit is the one you set for yourself",
    "Run, because you deserve to feel alive",
    /***/ "Running is the cheapest therapy there is",
    "Don't stop when you're tired, stop when you're done",
    /***/ "Life is better in running shoes",
    /***/ "You're a running machine!",
    /***/ "You're unstoppable!",
    "Go after your dreams!",
    "Run with purpose, run with passion!",
    "Your hard work will pay off, keep running!",
    /****/ "You're a warrior! Keep conquering each mile!",
    /****/ "Run, sweat, smile, repeat!",
    "Run towards your fears, and watch them disappear!",
    /****/ "Run now, brag later!",
    "The harder the run, the greater the victory!",
    "Run like a cheetah, chase your wildest dreams!",
    /****/ "Run to live, live to run!",
    "Run with determination and passion!",
    /***/ "Run towards your greatness!",
    "Run like a boss!",
    /****/ "Run, rise, shine!",
    /****/ "Run, sweat, inspire!",
    "Run with heart, run with soul!",
    "Run like a beast",
    "Run like a hero, and inspire others to follow!",
    /****/ "If running is easy, you're doing it wrong!",
    /****/ "Sweat is just your fat crying, so keep running!",
    /****/ "Run now, wine later!",
    /****/ "Run like your ex is watching!",
    /***/ "I'm not fast, I'm just full of enthusiasm!",
    /****/ "Run like a turtle, but without the shell!",
    "Run like a penguin, but without the waddling!",
    /***/ "Run like a giraffe, but without the neck!",
    "Take the first step and keep going!",
    "Every step counts towards your goals!",
    "You can do this, just keep going!",
    "Believe in yourself and keep running!",
    "The only limit is the one you set for yourself!",
    "Challenge yourself and push through the discomfort!",
    "The harder you work, the stronger you get!",
    "Keep running, because walking is for quitters.",
];